@import "core/index.scss";
.tabs-container {
	@include media {
		overflow: hidden;
	}

	&.plain {
		.tabs-labels {
			&:before {
				display: none;
			}
		}

			.labels-innerwrap {
				justify-content: flex-start;
			}

			.labels-item {
				border: none!important;
			}
	}
	
	.tabs-labels {
		position: relative;
		
		@include media {
			white-space: nowrap;
			overflow-x: scroll;
			width: 100%;
			margin: 0;
			text-align: center;
			padding: 0 .5rem;
		}

		&:before {
			@include pseudo;
			top: 50%;
			z-index: 1;
			height: .1rem;
			background-color: $color-gray;
			position: absolute;
			left: 0;
			right: 0;

			@include media {
				margin-top: -.75rem;
			}
		}
	}

		.labels-innerwrap {
			position: relative;
			width: 100%;
			display: flex;
			justify-content: center;
			z-index: 1;
			padding: 0 0 1.5rem;
			// padding: 0 1.5rem;

			@include media {
				display:inline-block;
				white-space: nowrap;
				width: auto;
				// margin: 0 3.5rem;
			}

			&:before {
				@include pseudo;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 1px;
				//background-color: rgba($color-text, .1);
				z-index: -1;

				@include media {
					bottom: .1rem;
				}
			}
		}

		.labels-item {
			border-left: .3rem solid $color-white;
			border-right: .3rem solid $color-white;

			&:first-child {
				border-left-width: 2.4rem;
			}
			&:last-child {
				border-right-width: 2.4rem;
			}

			@include media {
				display: inline-block;

				&:first-child {
					border-left-width: 1.5rem;
				}
				&:last-child {
					border-right-width: 1.5rem;
				}
			}
		}

		.item-btn {
			@include font-semibold;
			position: relative;
			background-color: $color-white;
			color: $color-text-light;
			cursor: pointer;
			font-size: 1.8rem;
			height: 4rem;
			display: inline-flex;
			align-items: center;
			z-index: 3;	
			padding: 0 1.8rem;
			border-radius: $radius-general;
			transition: background-color $transition-hover ease, color $transition-hover ease;
			display: inline-flex;
			justify-content: flex-start;
			align-items: center;

			&:after {
				@include pseudo;
				width: 0;
				height: 0;
				border: .7rem solid transparent;
				border-top-color: $color-blue-bg;
				position: absolute;
				left: 50%;
				top: 100%;
				opacity: 0;
				transform: translate3d(-50%, -50%, 0);
				transition: opacity $transition-hover ease, transform $transition-hover ease;
			}

			&.active {
				background-color: $color-blue-bg;
				color: $color-text-blue;

				&:after {
					transform: translate3d(-50%, 0, 0);
					opacity: 1;
				}
			}

			&.single {
				cursor: default;
			}
		}

			.btn-icon {
				margin-right: 1.1rem;
				font-size: 2.2rem;
				margin-bottom: .2rem;
			}

	.tabs-items {
		padding: 0;
	}
	
		.items-tab {
			opacity: 0;
			transition: opacity 350ms ease;
			display: none;

			&.active {
				display: block;
			}

			&.show {
				opacity: 1;
			}
		}
}