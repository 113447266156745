@import "core/index.scss";
.section.productcarousel {
	padding: 4.3rem 0 4.1rem;
	overflow: hidden;

	@include media {
		padding: 4rem 0 4rem;
	}

	&.wrap {
		@include mediaMin {
			.productcarousel-slider {
				@include maskSlider(4rem);
				width: calc(100% + 11rem);
				margin: 0 -5.5rem;
	
				&.static {
					.productcarousel-slide {
						max-width: calc(100% / 5);
					}
				}
			}
		}

		@include media {
			overflow: hidden;
		}
	}

	.productcarousel-wrap {
		@include media {
			padding: 0 4rem 0 0;
		}
	}

	.productcarousel-slider {
		@include media {
			width: calc(100% + 1.5rem);
			margin-left: -1.5rem;
			
			.sliderelem-swiper {
				overflow: visible;
			}
		}

		.swiper-button-prev {
			left: .5rem;
		}

		.swiper-button-next {
			right: .5rem;
		}
		
		.swiper-slide {
			height: auto;
		}
		
		&.static {
			display: flex;
			justify-content: flex-start;

			@include media {
				width: calc(100% + 3.5rem);
				margin-right: -3.5rem;
				// justify-content: flex-start;
			}

			.productcarousel-slide {
				max-width: calc(100% / 5);

				@include media {
					max-width: 50%;
				}
			}
		}
	}

	.productcarousel-slide {
		padding: 2rem 1rem;

		@include media {
			padding: 1rem 0 1rem;
			height: 100%;
			display: flex;
		}
	}
}