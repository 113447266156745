@import "core/index.scss";
.section{
	&.listing {
		padding-bottom: 2rem;
		
		.listing-wrap {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;

			@include media {
				display: block;
			}
		}

		.listing-filters {
			width: 23.2rem;
			margin-right: 2rem;
			border: .1rem solid $color-gray-light;
			border-radius: $radius-general;

			@include media {
				width: 100%;
				display: block;
				border: none;
				margin: 0 0 1.5rem;
			}

			.filters-head {
				padding: 2rem;

				@include media {
					padding: 0;
				}
			}

				.head-title {
					@include font-semibold;
					font-size: 1.5rem;
					
					@include media {
						display: inline-block;
					}

					&.placeholder {
						height: 1.5rem;
						width: 16rem;
					}
				}

				.head-results {
					@include font-semibold;
					text-transform: uppercase;
					font-size: 1.1rem;
					color: $color-text-light;
					margin-top: .6rem;
					line-height: 1.5rem;

					@include media {
						display: inline-block;
						margin: 0 0 0 1rem;
					}

					&.placeholder {
						height: 1.5rem;
						width: 14rem;
					}
				}

			.listing-filtergroup {
				padding: 2rem;
				border-top: .1rem solid $color-gray-light;
			}
		}

		.listing-content {
			flex: 1 0 0;

			@include media {
				width: calc(100% + 4rem);
				margin: 0 -2rem;
			}

			.content-banners {
				margin-bottom: 2rem;
				display: flex;
				justify-content: space-between;
				flex-flow: row wrap;

				@include media {
					display: block;
					overflow: hidden;
					padding-right: 3rem;
					padding-left: 1.5rem;
				}
			}

				.banners-slider {
					overflow: visible;
					width: 100%;

					.swiper-slide {
						padding: 0 .5rem;
					}
				}

				.banners-banner {
					width: calc(50% - 1rem);
					position: relative;
					display: block;

					@include media {
						width: 100%;
					}

					.banner-imagewrap {
						padding-bottom: 41.543026706231454%;
					}

					@include mediaMin {
						&.big {
							width: 100%;

							&:not(:last-child) {
								margin-bottom: 2rem;
							}
	
							.banner-imagewrap {
								padding-bottom: 40%;
							}
	
							.banner-contentwrap {
								padding: 2rem;
							}
	
							.banner-textcontent {
								background-color: $color-white;
								padding: 2rem;
								min-width: 30rem;
								max-width: 45rem;
							}
	
								.textcontent-title {
									@include font-regular;
									font-size: 3.6rem;
									letter-spacing: .025em;
									line-height: 1.2em;
								}
						}
					}
				}

					.banner-contentwrap {
						position: relative;
						z-index: 2;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						padding: 2rem 4rem;
						display: flex;
						align-items: center;
						justify-content: flex-start;

						@include media {
							padding: 2rem 40% 2rem 2rem;
						}
					}

					// .banner-textcontent {	
					// }

						.textcontent-label {
							margin-bottom: 2.2rem;
						}

						.textcontent-pretitle {
							@include font-bold;
							display: block;
							font-size: 1.8rem;
							margin-bottom: .8rem;

							@include media {
								font-size: 1.5rem;
								line-height: 1.3em;
							}
						}

						.textcontent-title {
							@include font-medium;
							display: block;
							font-size: 1.8rem;

							@include media {
								line-height: 1.3em;
								font-size: 1.6rem;
							}
						}

						.textcontent-arrow {
							color: $color-text-light;
							display: inline-block;
							margin-top: 1rem;
						}

						.textcontent-cta {
							margin-top: 2rem;
						}

					.banner-imagewrap {
						z-index: 1;
						background-color: $color-bg-light;
						position: relative;
					}

					.banner-image {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}

			.content-results {
				border: .1rem solid $color-gray-light;
				border-radius: $radius-general;
				padding: 2rem 0 0;

				@include media {
					padding: 0;
					border: none;
				}
			}

				.results-mobilecontrols {
					border: .1rem solid $color-gray-light;
					border-radius: $radius-general;
					width: calc(100% - 4rem);
					margin: 2rem 2rem 0;
					display: flex;
					align-items: center;
					justify-content: space-around;

					// > span {
					// 	height: 3rem;
					// 	width: .1rem;
					// 	background-color: $color-gray-light;
					// }
				}

					.mobilecontrols-btn {
						@include font-semibold;
						// flex: 1 0 0;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 5rem;
						font-size: 1.5rem;
						transition: background-color $transition-superfast ease;

						&:first-child:last-child {
							width: 100%;
						}

						&:active {
							background-color: $color-bg;
						}

						&.active {
							i {
								&:after {
									@include pseudo;
									position: absolute;
									width: .7rem;
									height: .7rem;
									border-radius: 50%;
									background-color: $color-red;
									top: -.3rem;
									right: -.2rem;
								}
							}
						}

						i {
							position: relative;
							margin-right: 1rem;
							font-size: 2rem;
						}
					}

				.results-filtersum {
					padding: 0 2rem;
					margin-bottom: 2rem;
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}

					.filtersum-filter {
						background-color: $color-bg;
						position: relative;
						display: flex;
						align-items: flex-start;
						font-size: 1.1rem;
						padding: .6rem .5rem;
						border-radius: $radius-general;
						margin-right: 1rem;
					}
					
						.filter-title {
							@include font-semibold;
							display: block;
							text-transform: uppercase;
							color: $color-text-lighter;
							font-size: .9rem;
							margin-bottom: .2rem;
						}

						.filter-value {
							max-width: 7rem;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}

						.filter-removebtn {
							display: flex;
							border-radius: 50%;
							width: 1.6rem;
							height: 1.6rem;
							font-size: .6rem;
							align-items: center;
							justify-content: center;
							margin-left: .5rem;
							color: $color-white;
							background-color: $color-gray-dark;
							transition: background-color $transition-hover ease;

							@include mediaMin {
								&:hover {
									background-color: $color-text;
								}
							}
						}
						
					.filtersum-clear {
						color: $color-text-light;
						text-decoration: underline;
						margin-left: 1rem;
						transition: color $transition-hover ease;

						@include mediaMin {
							&:hover {
								color: $color-text;
							}
						}
					}

				.results-order {
					margin: 0 2rem;
					border: .1rem solid $color-gray-light;
					border-radius: $radius-general;
					padding: 1rem 2rem;
					display: flex;
					align-items: center;
				}

					.order-title {
						@include font-bold;
						font-size: 1.2rem;
						text-transform: uppercase;
						margin-right: 1rem;
					}

					.order-opts {
						@include font-semibold;
						flex: 1 0 0;
						display: flex;
						align-items: flex-start;
					}

						.opts-opt {
							padding: 0 .4rem;
						}

							.opt-btn {
								padding: .5rem 1rem;
								font-size: 1.4rem;
								line-height: 1em;
								color: $color-text-blue-light;
								transition: background-color $transition-hover ease, color $transition-hover ease;

								&.active {
									background-color: $color-blue-bg;
									color: $color-text-blue;
								}

								@include mediaMin {
									&:hover {
										color: $color-text-blue;
									}
								}
							}

					.order-filter {
						color: $color-text-light;
					}

				.results-list {
					display: flex;
					flex-flow: row wrap;
					padding: 2rem 1rem;
					position: relative;
					z-index: 1;
				}

					.list-result {
						width: 25%;
						padding: 1rem .5rem;
						display: flex;

						$resultCount: 40;
						@for $i from 1 through $resultCount {
							&:nth-child(#{$i}) {
								.productbox {
									z-index: ($resultCount - ($i - 1));
								}
							}
						}

						@include media {
							width: 50%;

							.productbox-wrap {
								padding: 1rem;
							}

							.productbox-controls {
								&:not(.visible) {
									padding: 0 1rem;
								}
							}
						}
					}

						.result-product {
							display: flex;
							width: 100%;
						}

				.results-emptybox {
					display: flex;
					align-items: center;
					justify-content: center;
					min-height: 15rem;
					padding: 3rem 0;
					font-size: 2rem;
					color: $color-text-light;

					&.has-child-selection {
						align-items: flex-start;
					}

					@include media {
						padding-left: 2rem;
						padding-right: 2rem;
					}
				}
				
					.emptybox-icon {
						font-size: 7rem;
					}

					.emptybox-text {
						margin-left: 2rem;
					}

						.text-title {
							font-size: 2.4rem;
							margin-bottom: 1rem;
							display: block;
						}

						.text-childselection {
							margin-top: 2rem;
							font-size: 1.5rem;
							background-color: $color-bg-light;
							padding: 2rem;
							border-radius: $radius-general;

							> * + * {
								margin-top: .5rem;
							}
						}

							.childselection-clear {
								margin-top: 1rem;
								text-decoration: underline;
								color: $color-text-blue;
							}

				.results-pagination {
					padding: 3rem 2rem;
					border-top: .1rem solid $color-gray-light;
				}
		}

		.listing-description {
			// @include font-semibold;
			// color: $color-text-light;
			width: 100%;
			padding: 5rem 0;
			text-align: justify;
			font-size: 1.4rem;

			@include media {
				padding: 3rem 2rem;
			}
		}

		.listing-mobilefilters {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 10;
			background-color: $color-white;
			opacity: 0;
			transform: translate3d(0, 2rem, 0);
			transition: opacity $transition-hover ease, transform $transition-hover ease;
			padding-bottom: 9rem;
			overflow-y: scroll;

			&.show {
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}

			&.sub {
				.mobilefilters-head {
					justify-content: flex-start;
				}
			}

			.mobilefilters-head {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 2rem;
				border-bottom: .1rem solid $color-gray-light;
				height: 6rem;
				margin-top: var(--safe-area-insets-top, env(safe-area-inset-top));
			}

				.head-title {
					font-size: 1.5rem;
				}

					.title-total {
						font-size: 1.2rem;
						color: $color-text-light;
						margin-top: .3rem;
					}

				.head-btn {
					&.back {
						margin-right: 2rem;
					}
				}

			.mobilefilters-content {
				height: calc(100% - 11rem);
				overflow-y: scroll;
			}

			// .mobilefilters-filters {
			// }

				.filters-filter {
					padding: 2rem 2rem;
					display: block;
					width: 100%;
					position: relative;

					&:active {
						background-color: $color-bg;
					}

					+ .filters-filter {
						&:before {
							@include pseudo;
							position: absolute;
							top: 0;
							left: 2rem;
							right: 2rem;
							height: .1rem;
							background-color: $color-gray-light;
						}
					}
				}
					
					.filter-values {
						color: $color-text-light;
						margin-top: .5rem;
						span {
							+ span {
								&:before {
									content: ', ';
								}
							}
						}
					}

			.mobilefilters-filterdetail {
				padding: 2rem 2rem 0;

				.checkwrap {
					width: 100%;
					display: block;
				}
			}

			.mobilefilters-ctawrap {
				padding: 2rem;
				background-color: $color-white;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
			}
		}

		.listing-mobileorder {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 10;
			background-color: $color-white;
			opacity: 0;
			transform: translate3d(0, 2rem, 0);
			transition: opacity $transition-hover ease, transform $transition-hover ease;
			overflow-y: scroll;
			padding-bottom: 9rem;

			&.show {
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}

			.mobileorder-head {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 2rem;
				border-bottom: .1rem solid $color-gray-light;
				height: 6rem;
				margin-top: var(--safe-area-insets-top, env(safe-area-inset-top));
			}

				.head-title {
					font-size: 1.5rem;
				}

			.mobileorder-content {
				height: calc(100% - 11rem);
			}

				.content-opts {
					@include font-semibold;
					font-size: 1.5rem;
				}

					.opts-opt {
						position: relative;

						&:active {
							background-color: $color-bg;
						}

						+ .opts-opt {
							&:before {
								@include pseudo;
								position: absolute;
								top: 0;
								left: 2rem;
								right: 2rem;
								top: 0;
								height: .1rem;
								background-color: $color-gray-light;
							}
						}
					}

						.opt-btn {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							padding: 1.5rem 2rem;
							width: 100%;

							&.active {
								&:before {
									border-color: $color-blue;
									background-color: $color-blue;
									color: $color-white;
								}
							}
							
							&:before {
								@include icon('check');
								color: rgba($color-white, 0);
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 1.2rem;
								margin-right: 1rem;
								width: 2.4rem;
								height: 2.4rem;
								border-radius: 50%;
								border: .1rem solid $color-gray-light;
								transition: background-color $transition-hover ease, color $transition-hover ease, border-color $transition-hover ease;
							}
						}

				.content-filter {
					margin-top: 2rem;
					width: 100%;
					padding-left: 2rem;
					padding-right: 2rem;

					+ .content-filter {
						margin-top: 0;
					}
				}

			.mobileorder-ctawrap {
				border-top: .1rem solid $color-gray-light;
				padding: 2rem;
				background-color: $color-white;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
			}
		}

		.listing-filtergroup {
			@include media {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;

				&.type-age-display {
					padding: 2rem;
					height: auto;
				}
			}

			&.type-age-display {
				background-color: $color-bg;
				border-top: none;

				&.gender-girl {
					background-color: $color-pink-bg;
				}

				&.gender-boy {
					background-color: $color-blue-bg;
				}

				&.gender-boy, &.gender-girl {
					+ .listing-filtergroup {
						border-top: none;
					}
				}

				.display-title {
					@include font-medium;
					display: block;
					margin-bottom: 1rem;
					line-height: 1.2em;
					font-size: 1.2rem;
				}

				.display-content {
					display: flex;
					align-items: center;
				}

					.content-icon {
						margin-right: 1rem;
						font-size: 2.4rem;
					}

				.display-childname {
					display: block;
					margin-bottom: .3rem;
				}

				.display-features {
					font-size: 1.3rem;

					> * + * {
						&:before {
							content: ', ';
						}
					}
				}

				.display-clearbtn {
					text-decoration: underline;
					margin-top: 1rem;
					font-size: 1.2rem;
				}
			}

			&.type-default {
				.options-option {
					padding: .1rem 0;

					@include media {
						padding: 1rem 0;

						+ .options-option {
							border-top: .1rem solid $color-gray-light;
						}
					}
				}

					.option-input {
						padding: 0;

						&.input-full {
							.checkwrap {
								@include font-semibold;
							}
						}
					}

						.input-count {
							@include font-regular;
							margin-left: .5rem;
							color: $color-text-lightest;
						}
			}

			&.type-color {
				.options-option {
					padding: .1rem 0;

					@include media {
						padding: 1rem 0;

						+ .options-option {
							border-top: .1rem solid $color-gray-light;
						}
					}
				}

					.option-input {
						padding: 0;

						&.input-full {
							.checkwrap {
								@include font-semibold;
							}
						}
					}

						.input-color {
							display: inline-block;
							margin-left: .7rem;
							border-radius: $radius-general;
							width: 1rem;
							height: 1rem;
							vertical-align: middle;
							margin-top: -.3em;
							box-shadow: $shadow-general;
						}
			}

			&.type-grouped {
				.group-optionswrap {
					margin-top: 2rem;
				}
				
				.options-subgroup {
					+ .options-subgroup {
						margin-top: 1rem;
					}
				}

					.subgroup-title {
						@include font-medium;
						display: block;
						font-size: 1.3rem;
						margin-bottom: 1rem;
					}

				.options-option {
					padding: .1rem 0;
				}

					.option-input {
						padding: 0;

						&.input-full {
							.checkwrap {
								@include font-semibold;
							}
						}
					}

						.input-count {
							@include font-regular;
							margin-left: .5rem;
							color: $color-text-lightest;
						}
			}

			&.type-range {
				.group-range {
				}
				
					.range-inputs {
						display: flex;
						justify-content: space-between;
						align-items: center;
					}

					.range-input {
						display: inline-block;
						width: calc((100% - 8rem) / 2);
						border: .1rem solid $color-gray-light;
						border-radius: $radius-general;
						line-height: 3.2rem;
						padding: .2rem .2rem;
						text-align: center;
						font-size: 1.3rem;
						color: $color-text-light;
					}

					.range-submit {
						width: 5rem;
						margin-left: .5rem;
					}

					.range-clear {
						@include font-medium;
						text-decoration: underline;
						color: $color-text-blue;
						margin-top: 1rem;
						font-size: 1.4rem;
					}
			}

			.group-searchfilter {
				display: block;
				width: 100%;
				margin-bottom: 1rem;
				background-color: $color-bg-light;
				border-radius: $radius-general;
				padding: 1rem 1rem;
				line-height: 1.6rem;
				font-size: 1.3rem;

				@include media {
					margin-bottom: 1.5rem;
				}
			}

			.group-head {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 1rem;

				@include media {
					justify-content: flex-end;
					margin-bottom: 1.5rem;
				}
			}

				.head-sortbtn {
					@include font-semibold;
					font-size: 1.2rem;
					border: .1rem solid $color-gray-light;
					background-color: $color-gray-light;
					color: $color-text-light;
					border-radius: $radius-general;
					display: flex;
					align-items: center;
					padding: .3rem .5rem;
					transition: background-color $transition-hover ease, border-color $transition-hover ease;

					&.default {
						background-color: $color-white;
						color: $color-text-lighter;
					}

					i {
						font-size: .9rem;
						margin-left: .3rem;
					}
				}
			
				.head-title {
					@include font-semibold;
					display: block;
					font-size: 1.5rem;
				}

			.group-optionswrap {
				max-height: 30rem;
				padding-right: 2rem;
				margin-right: -1rem;
				margin-top: 1rem;
				width: calc(100% + 1rem);

				@include media {
					max-height: none;
					flex: 1 0 0;
					overflow-y: auto;
				}
			}

			.group-options {
				color: $color-text-light;
			}

				.options-option {
					padding: .5rem 0;
					line-height: 1.2em;
				}
		}

		.listing-suggestions {
			padding: 4rem 0 2rem;

			@include media {
				padding: 1rem 0;
			}

			.suggestions-title {
				margin-bottom: 1.5rem;
			}

			.suggestions-list {
				display: flex;
				flex-wrap: wrap;
				margin: 0 -1.5rem 0 0;
				width: calc(100% + 1.5rem);
			}

				.list-item {
					margin-right: 1.5rem;
				}
				
					.item-link {
						display: block;
						border: .1rem solid $color-gray;
						border-radius: 1.5rem;
						line-height: 2rem;
						padding: .5rem 1rem;
						transition: background-color $transition-hover ease;

						&.placeholder {
							height: 3rem;
							width: 12rem;
						}

						&:hover {
							background-color: $color-bg;
						}
					}
		}
	}

	// &.listing-recentproducts {
	// 	// content-visibility: auto;
	// }
}