@import "core/index.scss";
.section.breadcrumbs {
	@include font-medium;
	position: relative;
	z-index: 1;
	font-size: 1.4rem;
	color: $color-text-light;

	.breadcrumbs-outerwrap {
		width: calc(100% + 6rem);
		margin: 0 -3rem;
		min-height: 7rem;
		overflow: hidden;

		@include media {
			width: calc(100% + 4rem);
			margin: 0 -2rem;
			min-height: 4rem;
		}
	}

	.breadcrumbs-wrap {
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		overflow-x: auto;

		&:before, &:after {
			@include pseudo;
			background: $color-white;
			z-index: 4;
			position: absolute;
			top: 0;
			bottom: 2rem;
			width: 3rem;
			right: 0;
			pointer-events: none;
			background: linear-gradient(-90deg, $color-white, rgba($color-white, 80%), rgba($color-white, 0));

			@include media {
				width: 2rem;
				bottom: 0;
			}
		}

		&:before {
			left: 0;
			right: auto;
			transform: rotate(180deg);
		}
	}

	.breadcrumbs-list {
		white-space: nowrap;
		padding: 2.5rem 0;
		line-height: 2rem;

		@include media {
			padding: 0 0 1rem;
		}
	}

		.breadcrumbs-item {
			display: inline-block;

			&:first-child {
				padding-left: 3rem;

				@include media {
					padding-left: 2rem;
				}
			}

			&:last-child {
				padding-right: 3rem;

				@include media {
					padding-right: 2rem;
				}
			}

			+ .breadcrumbs-item {
				margin-left: .5rem;

				&:before {
					@include icon('angle-right');
					font-size: .5rem;
					vertical-align: middle;
					margin-right: .5rem;
				}
			}
		}

			.item-link {
				transition: color $transition-hover ease;

				@include mediaMin {
					&:hover {
						color: darken($color-text-light, $darken-ratio);
					}
				}

				&.placeholder {
					width: 10rem;
					height: 2rem;
					display: inline-block;
					margin-top: -.2rem;
					vertical-align: middle;
				}

				&:active, &:focus {
					color: $color-text;
				}
			}

	// App Styles
	&.type-app {
		.breadcrumbs-list {
			@include media {
				padding-top: 1rem;
			}
		}
	}
}