@mixin maskSlider($width: 8rem, $bg: $color-white, $physicalGap: true){
	position: relative;
	
	@if $physicalGap {
		padding-left: $width;
		padding-right: $width;
	}

	&:before, &:after {
		@include pseudo;
		position: absolute;
		top: 0;
		bottom: 0;
		width: $width;
		left: 0;
		background-color: $bg;
		z-index: 3;
	}

	&:after {
		right: 0;
		left: auto;
	}
}