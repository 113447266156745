@import "core/index.scss";
@import 'simplebar/dist/simplebar.min.css';

.simplebar-track {
	&.simplebar-hover {
		.simplebar-scrollbar {
			&:before {
				opacity: .8;
			}
		}
	}

	.simplebar-scrollbar {
		cursor: pointer;
		
		&:before {
			transition: opacity $transition-hover ease;
			background-color: $color-gray;
			cursor: pointer;
		}
	}

	&.simplebar-horizontal {
		height: 1.2rem;
		.simplebar-scrollbar {
			top: .2rem;
			height: .8rem;
		}
	}

	&.simplebar-vertical {
		width: 1.2rem;
		.simplebar-scrollbar {
			left: .2rem;
			width: .8rem;
		}
	}
}