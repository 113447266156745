@import "core/index.scss";
.section-title-component {
	@include font-bold;
	display: block;
	font-size: 2.4rem;
	// text-align: center;
	text-align: left;
	
	@include media {
		font-size: 1.8rem;
	}
}