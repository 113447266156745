@import "core/index.scss";
.productbox-wrap {
	position: relative;
	display: block;
	background-color: $color-white;
	border-radius: $radius-general;
	z-index: 1;

	&:before {
		@include pseudo;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: $radius-general;
		box-shadow: $shadow-big;
		opacity: 0;
		transition: opacity $transition-hover ease;
		border: .1rem solid $color-gray-light;
		z-index: -1;
		pointer-events: none;
	}
}

.productbox {
	display: block;
	position: relative;

	@include mediaMin {
		&:hover {
			.productbox-wrap, &.productbox-wrap {
				&:before {
					opacity: 1;
				}
	
			}

			.productbox-controls:not(.visible) {
				opacity: 1;
				transform: translate3d(0, 0, 0)!important;
			}
		}
	}

	.productbox-labels {
		z-index: 2;
		> * {
			margin-right: .5rem;
			margin-bottom: .3rem;

			+ * {
				margin-left: 0;
			}
		}
	}

	.productbox-controls {
		position: relative;
		padding: 1.5rem 2rem 2rem;
		background-color: $color-white;
		z-index: 2;
		
		&:not(.visible) {
			position: absolute;
			bottom: .1rem;
			left: .1rem;
			right: .1rem;
			opacity: 0;
			transform: translate3d(0, 1rem, 0);
			transition: opacity $transition-hover ease, transform $transition-hover ease;
			// box-shadow: $shadow-wide;
			background-color: transparent;

			> * {
				position: relative;
				z-index: 2;
			}

			&:before, &:after {
				@include pseudo;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
			}

			&:before {
				top: 0;
				height: 2rem;
				background-color: $color-white;
				background: linear-gradient(0deg, $color-white, rgba($color-white, 100%), rgba($color-white, 0));
			}

			&:after {
				top: 2rem;
				background-color: $color-white;
			}
			
			@include media {
				display: none;
			}
		}
	}

	.productbox-imagewrap {
		position: relative;
		// &.placeholder {
		// 	background-color: $color-blue-bg-dark;
		// }
	}

		.imagewrap-image {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: center;
		}

	.productbox-title {
		@include font-medium;
		overflow: hidden;
		display: block;
		line-height: 1.33333em;
		color: $color-text-light;
	}

	.productbox-price {

		&.placeholder {
			width: 9rem;
			height: 4rem;
		}
	}

	.productbox-giftnote {
		background-color: $color-success-bg;
		padding: 1rem;
		margin-top: 1rem;
		max-width: 50rem;

		.giftnote-head {
			display: flex;
			align-items: center;
		}

			.head-icon {
				font-size: 1.7rem;
				margin-right: 1.4rem;
			}

			.head-title {
				flex: 1 0 0;
				font-size: 1.5rem;
			}

		.giftnote-bottom {
			margin-top: 1rem;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}

			.bottom-message {
				color: $color-success;
			}

				.message-note {
					margin-top: .3rem;
					font-style: italic;
				}

			.bottom-controls {
				margin-left: 1rem;
				display: flex;
				justify-content: flex-end;
				align-items: center;

				> * + * {
					margin-left: 1rem;
				}
			}
	}
}